import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const fetchSherginaMineArticles = createAsyncThunk(
	'sherginaMine/fetchArticles',
	async () => {
		const response = await fetch('/articlesData.json')
		const data = await response.json()
		return data.sherginaMine
	}
)

export const sherginaMineSlice = createSlice({
	name: 'sherginaMine',
	initialState: {
		articles: [],
		status: 'idle', // может быть 'idle', 'loading', 'succeeded', 'failed'
		error: null,
	},
	reducers: {},
	extraReducers(builder) {
		builder
			.addCase(fetchSherginaMineArticles.pending, (state, action) => {
				state.status = 'loading'
			})
			.addCase(fetchSherginaMineArticles.fulfilled, (state, action) => {
				state.status = 'succeeded'
				// Добавляем загруженные статьи в состояние
				state.articles = action.payload
			})
			.addCase(fetchSherginaMineArticles.rejected, (state, action) => {
				state.status = 'failed'
				state.error = action.error.message
			})
	},
})

export default sherginaMineSlice.reducer
