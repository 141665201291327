import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const fetchDiscoveredArticles = createAsyncThunk(
	'discovered/fetchArticles',
	async () => {
		const response = await fetch('/articlesData.json')
		const data = await response.json()
		return data.discovered
	}
)

export const discoveredSlice = createSlice({
	name: 'discovered',
	initialState: {
		articles: [],
		status: 'idle',
		error: null,
	},
	reducers: {},
	extraReducers(builder) {
		builder
			.addCase(fetchDiscoveredArticles.pending, state => {
				state.status = 'loading'
			})
			.addCase(fetchDiscoveredArticles.fulfilled, (state, action) => {
				state.status = 'succeeded'
				state.articles = action.payload
			})
			.addCase(fetchDiscoveredArticles.rejected, (state, action) => {
				state.status = 'failed'
				state.error = action.error.message
			})
	},
})

export default discoveredSlice.reducer
