import React from 'react'
import ArticlesPage from '../components/ArticlesPage'
import { fetchKarbazBoardsArticles } from '../features/karbazBoards/karbazBoardsSlice'

const KarbazBoardsPage = () => {
	return (
		<ArticlesPage
			fetchArticles={fetchKarbazBoardsArticles}
			sliceName='karbazBoards'
			pageTitle='Карбазные доски'
		/>
	)
}

export default KarbazBoardsPage
