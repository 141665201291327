import React from 'react'
import ArticlesPage from '../components/ArticlesPage'
import { fetchDiscoveredArticles } from '../features/discovered/discoveredSlice'

const DiscoveredPage = () => {
	return (
		<ArticlesPage
			fetchArticles={fetchDiscoveredArticles}
			sliceName='discovered'
			pageTitle='Стенд первооткрывателей'
		/>
	)
}

export default DiscoveredPage
