import React from 'react'
import ArticlesPage from '../components/ArticlesPage'
import { fetchCampfireInstallationArticles } from '../features/campfireInstallation/campfireInstallationSlice'

const CampfireInstallationPage = () => {
	return (
		<ArticlesPage
			fetchArticles={fetchCampfireInstallationArticles}
			sliceName='campfireInstallation'
			pageTitle='Инсталляция костра'
		/>
	)
}

export default CampfireInstallationPage
