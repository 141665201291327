import React, { memo, useState } from 'react'
import { NavLink } from 'react-router-dom'

const Header = memo(() => {
	const [isMenuOpen, setIsMenuOpen] = useState(false)

	return (
		<header className='text-white top-0 left-0 right-0 z-50 relative'>
			<nav className='container mx-auto flex flex-col md:flex-row md:justify-center items-center py-4'>
				<div
					className='md:hidden mb-4 md:mb-0 cursor-pointer'
					onClick={() => setIsMenuOpen(!isMenuOpen)}
				>
					<i className='text-3xl'>
						{isMenuOpen ? (
							<svg
								xmlns='http://www.w3.org/2000/svg'
								fill='none'
								viewBox='0 0 24 24'
								strokeWidth={1.5}
								stroke='currentColor'
								className='w-6 h-6'
							>
								<path
									strokeLinecap='round'
									strokeLinejoin='round'
									d='M6 18L18 6M6 6l12 12'
								/>
							</svg>
						) : (
							<svg
								xmlns='http://www.w3.org/2000/svg'
								fill='none'
								viewBox='0 0 24 24'
								strokeWidth={1.5}
								stroke='currentColor'
								className='w-6 h-6'
							>
								<path
									strokeLinecap='round'
									strokeLinejoin='round'
									d='M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5'
								/>
							</svg>
						)}
					</i>
				</div>

				<ul
					className={`text-center ${
						isMenuOpen ? 'block' : 'hidden'
					} md:flex space-x-0 md:space-x-4 md:justify-center`}
				>
					<li>
						<NavLink
							to='/'
							className='hover:text-gray-400'
							onClick={() => setIsMenuOpen(false)}
						>
							Главная
						</NavLink>
					</li>
					<li>
						<NavLink
							to='/quest'
							className='hover:text-gray-400'
							onClick={() => setIsMenuOpen(false)}
						>
							Задания
						</NavLink>
					</li>
					<li>
						<NavLink
							to='/about-project'
							className='hover:text-gray-400'
							onClick={() => setIsMenuOpen(false)}
						>
							О проекте
						</NavLink>
					</li>
					<li>
						<NavLink
							to='/about-us'
							className='hover:text-gray-400'
							onClick={() => setIsMenuOpen(false)}
						>
							О нас
						</NavLink>
					</li>
					<li>
						<NavLink
							to='/contact'
							className='hover:text-gray-400'
							onClick={() => setIsMenuOpen(false)}
						>
							Контакты
						</NavLink>
					</li>
				</ul>
			</nav>
			<div className='line-after'></div>
		</header>
	)
})

export default Header
