import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Header from './components/Header'
import Footer from './components/Footer'
import HomePage from './pages/HomePage'
import QuestPage from './pages/QuestPage'
import SherginaMinePage from './pages/SherginMineTaskPage'
import KarbazBoardsPage from './pages/KarbazBoardTaskPage'
import TelescopePage from './pages/TelescopeTaskPage'
import PioneersStandPage from './pages/DiscoveredStandTaskPage'
import CampfireInstallationPage from './pages/CampfireInstallationTaskPage'
import AboutProject from './pages/AboutProject'
import AboutUs from './pages/AboutUs'
import Contacts from './pages/Contacts'

function App() {
	return (
		<Router>
			<div className='home-page flex flex-col justify-center min-h-screen w-full'>
				<Header />
				<main className='flex-grow'>
					<Routes>
						<Route path='/' element={<HomePage />} />
						<Route path='/quest' element={<QuestPage />} />
						<Route path='/shergina-mine' element={<SherginaMinePage />} />
						<Route path='/karbaz-boards' element={<KarbazBoardsPage />} />
						<Route path='/telescope' element={<TelescopePage />} />
						<Route path='/discovered-stand' element={<PioneersStandPage />} />
						<Route
							path='/campfire-installation'
							element={<CampfireInstallationPage />}
						/>
						<Route path='/about-project' element={<AboutProject />} />
						<Route path='/about-us' element={<AboutUs />} />
						<Route path='/contact' element={<Contacts />} />
					</Routes>
				</main>
				<Footer />
			</div>
		</Router>
	)
}

export default App
