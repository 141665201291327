import React from 'react'
import { Link } from 'react-router-dom'

const QuestPage = () => {
	const tasks = [
		{
			name: 'Шахта Шергина',
			image: '/images/Tasks/SherginMine1.png',
			description:
				'Погрузитесь в историю заброшенной шахты и узнайте её секреты.',
			to: '/shergina-mine',
			articleIds: [111, 112, 113, 114, 115],
		},
		{
			name: 'Карбазные доски',
			image: '/images/Tasks/Karbaz1.png',
			description:
				'Исследуйте карбазные доски и откройте для себя историческое значение этого места.',
			to: '/karbaz-boards',
			articleIds: [211, 212],
		},
		{
			name: 'Подзорная труба',
			image: '/images/Tasks/Telescope1.png',
			description:
				'Изучите звёздное небо и научитесь определять созвездия с помощью подзорной трубы.',
			to: '/telescope',
			articleIds: [311, 312, 313],
		},
		{
			name: 'Стенд первооткрывателей',
			image: '/images/Tasks/Discovered1.png',
			description:
				'Отдайте дань уважения первооткрывателям, чьи открытия изменили мир.',
			to: '/discovered-stand',
			articleIds: [411, 412, 413, 414, 415, 416, 417],
		},
		{
			name: 'Инсталляция костра',
			image: '/images/Tasks/Campfire1.png',
			description:
				'Окунитесь в атмосферу традиционных сборищ и послушайте истории у костра.',
			to: '/campfire-installation',
			articleIds: [511, 512, 513],
		},
	]

	const completedTasks = JSON.parse(
		localStorage.getItem('completedTasks') || '[]'
	)

	return (
		<div className='container mx-auto text-center py-8 text-white'>
			<div className='max-w-[600px] mx-auto bg-black bg-opacity-70 p-4 rounded-3xl'>
				<h1 className='text-3xl font-bold  mb-2 text-center'>
					Здесь собраны важные моменты прошлого
				</h1>
			</div>
			<div className='max-w-[820px] mx-auto bg-black bg-opacity-70 p-4 mt-8 rounded-3xl'>
				<p className='mx-auto mb-8 text-white max-w-4xl mt-10'>
					В прошлом За́лог служил своеобразной границей города, а его территория
					была связана с важными историческими событиями и личностями, включая
					первопроходцев и исследователей. Эта часть Якутска служила важным
					пунктом на пути экспедиций и путешествий в глубь Сибири и Дальнего
					Востока, что придает ей особое культурное и историческое значение. На
					данный момент это единственный квартал в городе, где сохранились
					подлинные памятники истории и архитектуры, которые нуждаются в
					реставрации.
				</p>
			</div>
			<div className='max-w-[520px] mx-auto bg-black bg-opacity-70 p-4 mt-16 rounded-3xl'>
				<p className='text-white'>
					Выберите объект и изучите все его фрагменты
				</p>
			</div>

			<div className='grid grid-cols-1 mt-6 lg:grid-cols-3 lg:mt-8 xl:grid-cols-5 gap-5 xl:mt-16'>
				{tasks.map((task, index) => {
					const taskCompleted = task.articleIds.every(id =>
						completedTasks.includes(id)
					)

					return (
						<Link
							key={index}
							to={task.to}
							className='flex flex-col overflow-hidden max-w-[500px] shadow-lg mb-4 mx-auto relative cursor-pointer transition-transform duration-300 ease-in-out hover:scale-110 rounded-2xl'
						>
							<img
								className='w-full h-60 object-cover'
								src={task.image}
								alt={task.name}
							/>
							<div className='absolute inset-0 bg-black transition-opacity duration-300 ease-in-out hover:opacity-0 opacity-80 flex flex-col justify-between p-6'>
								<div className='text-white font-bold text-xl'>{task.name}</div>
								<p className='text-gray-300 text-base'>{task.description}</p>
								<div
									className={`text-sm ${
										taskCompleted ? 'text-green-500' : 'text-yellow-500'
									}`}
								>
									{taskCompleted
										? 'Собраны все фрагменты'
										: 'Несколько фрагментов не хватает'}
								</div>
							</div>
						</Link>
					)
				})}
			</div>
		</div>
	)
}

export default QuestPage
