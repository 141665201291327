import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const fetchKarbazBoardsArticles = createAsyncThunk(
	'karbazBoards/fetchArticles',
	async () => {
		const response = await fetch('/articlesData.json')
		const data = await response.json()

		return data.karbazBoards
	}
)

export const karbazBoardsSlice = createSlice({
	name: 'karbazBoards',
	initialState: {
		articles: [],
		status: 'idle',
		error: null,
	},
	reducers: {},
	extraReducers(builder) {
		builder
			.addCase(fetchKarbazBoardsArticles.pending, state => {
				state.status = 'loading'
			})
			.addCase(fetchKarbazBoardsArticles.fulfilled, (state, action) => {
				state.status = 'succeeded'
				state.articles = action.payload
			})
			.addCase(fetchKarbazBoardsArticles.rejected, (state, action) => {
				state.status = 'failed'
				state.error = action.error.message
			})
	},
})

export default karbazBoardsSlice.reducer
