import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const fetchCampfireInstallationArticles = createAsyncThunk(
	'campfireInstallation/fetchArticles',
	async () => {
		const response = await fetch('/articlesData.json')
		const data = await response.json()
		return data.campfireInstallation
	}
)

export const campfireInstallationSlice = createSlice({
	name: 'campfireInstallation',
	initialState: {
		articles: [],
		status: 'idle',
		error: null,
	},
	reducers: {},
	extraReducers(builder) {
		builder
			.addCase(fetchCampfireInstallationArticles.pending, state => {
				state.status = 'loading'
			})
			.addCase(fetchCampfireInstallationArticles.fulfilled, (state, action) => {
				state.status = 'succeeded'
				state.articles = action.payload
			})
			.addCase(fetchCampfireInstallationArticles.rejected, (state, action) => {
				state.status = 'failed'
				state.error = action.error.message
			})
	},
})

export default campfireInstallationSlice.reducer
