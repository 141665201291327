import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const fetchTelescopeArticles = createAsyncThunk(
	'telescope/fetchArticles',
	async () => {
		const response = await fetch('/articlesData.json')
		const data = await response.json()
		return data.telescope
	}
)

export const telescopeSlice = createSlice({
	name: 'telescope',
	initialState: {
		articles: [],
		status: 'idle',
		error: null,
	},
	reducers: {},
	extraReducers(builder) {
		builder
			.addCase(fetchTelescopeArticles.pending, state => {
				state.status = 'loading'
			})
			.addCase(fetchTelescopeArticles.fulfilled, (state, action) => {
				state.status = 'succeeded'
				state.articles = action.payload
			})
			.addCase(fetchTelescopeArticles.rejected, (state, action) => {
				state.status = 'failed'
				state.error = action.error.message
			})
	},
})

export default telescopeSlice.reducer
