// src/pages/SherginaMinePage.js
import React from 'react'
import ArticlesPage from '../components/ArticlesPage'
import { fetchSherginaMineArticles } from '../features/sherginaMine/sherginaMineSlice'

const SherginaMinePage = () => {
	return (
		<ArticlesPage
			fetchArticles={fetchSherginaMineArticles}
			sliceName='sherginaMine'
			pageTitle='Шахта Шергина'
		/>
	)
}

export default SherginaMinePage
