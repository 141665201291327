import React from 'react'

const Footer = () => {
	return (
		<footer className=' text-white text-center p-4 relative'>
			<div className='line-before w-[50%]'></div>
			<p>© AI.Architects, Tomsk</p>

			<p>2024</p>
		</footer>
	)
}

export default Footer
