import React from 'react'

const AboutProject = () => {
	return (
		<div className='container mx-auto p-4 text-white mt-4'>
			<div className='max-w-4xl mx-auto'>
				<div className='min-w-[320px] max-w-[350px] mx-auto bg-black bg-opacity-70 p-4 rounded-3xl'>
					<h1 className='text-3xl font-bold  mb-2 text-center'>О проекте</h1>
				</div>

				<div className='mx-auto bg-black bg-opacity-70 p-4 mt-6 rounded-2xl'>
					<h3 className='text-xl font-bold mb-2'>Цели проекта:</h3>
					<ul className='list-disc list-inside mb-6'>
						<li>
							Создание приложения с использованием технологий дополненной
							реальности и геймификации для изучения истории и культуры Залога;
						</li>
						<li>
							Погружение посетителей в атмосферу экспедиции и путешествий через
							интерактивные элементы (МАФы) и дополненную реальность;
						</li>
						<li>
							Популяризация истории освоения Севера и Якутии, а также биографий
							первооткрывателей и значимых исторических фактов.
						</li>
					</ul>
				</div>

				<div className='mx-auto bg-black bg-opacity-70 p-4 mt-3 rounded-2xl'>
					<h3 className='text-xl font-bold  mb-2'>Описание Залога:</h3>
					<ul className='list-disc list-inside mb-4'>
						<li>
							Исторический квартал в г. Якутск, известный своими памятниками
							истории и архитектуры;
						</li>
						<li>
							Играл ключевую роль в экспедициях и исследованиях Сибири и
							Дальнего Востока.
						</li>
					</ul>
				</div>

				<div className='mx-auto bg-black bg-opacity-70 p-4 mt-3 rounded-2xl'>
					<h3 className='text-xl font-bold  mb-2'>
						Полное погружение в атмосферу экспедиции:
					</h3>
					<ul className='list-disc list-inside mb-4'>
						<li>
							Аудиальное - слушать истории в приложении AR, обсудить их с
							другими посетителями;
						</li>
						<li>
							Визуальное - возможность увидеть объекты в физическом мире,
							посмотреть иллюстрации и статьи в виртуальном;
						</li>
						<li>
							Кинестетическое - потрогать бинокль, потереть глаз экспедитору
							(как пример).
						</li>
					</ul>
				</div>

				<div className='mx-auto bg-black bg-opacity-70 p-4 mt-3 rounded-2xl'>
					<h3 className='text-xl font-bold  mb-2'>Целевая аудитория:</h3>
					<ul className='list-disc list-inside mb-4'>
						<li>Исследователи и историки;</li>
						<li>Туристы;</li>
						<li>Школьники и студенты.</li>
					</ul>
				</div>

				<div className='mx-auto bg-black bg-opacity-70 p-4 mt-3 rounded-2xl'>
					<h3 className='text-xl font-bold  mb-2'>Ожидаемый результат:</h3>
					<ul className='list-disc list-inside'>
						<li>Углубленное понимание истории и культуры Залога;</li>
						<li>
							Повышение интереса к исследованию истории Севера среди местных
							жителей и туристов;
						</li>
						<li>
							Сохранение и популяризация культурного наследия через современные
							технологии.
						</li>
					</ul>
				</div>
			</div>
		</div>
	)
}

export default AboutProject
