import React from 'react'

const Contacts = () => {
	return (
		<div className='container mx-auto p-4 text-white mt-4 text-center'>
			<div className='max-w-4xl mx-auto'>
				<div className='max-w-[340px] mx-auto bg-black bg-opacity-70 p-4 rounded-3xl'>
					<h1 className='text-3xl font-bold mb-2 text-center'>Контакты</h1>
				</div>

				<div className='max-w-[640px] mx-auto bg-black bg-opacity-70 p-4 mt-6 rounded-2xl'>
					<p>
						Если у вас есть вопросы или предложения, вы можете связаться с нами
						по почте:
					</p>
					<p className='text-xl font-bold'>ivk36@tpu.ru</p>
				</div>
			</div>
		</div>
	)
}

export default Contacts
