import React from 'react'
import { Link } from 'react-router-dom'

const HomePage = () => {
	return (
		<div className='container mx-auto text-center py-8 '>
			<h1 className='text-4xl text-white font-bold mt-14 mb-4'>За́лог</h1>
			<h2 className='text-2xl text-white font-bold mb-14'>
				Место, где начинается история Севера
			</h2>
			<div className='max-w-[300px] mx-auto p-2'>
				<Link
					to='/quest'
					className='flex items-center flex-col gap-2 justify-center hover-zoom'
				>
					<p className='text-white'>Начни путешествие в прошлое</p>
					<img
						src='/svg/compassroundedtool_104674.svg'
						alt='SVG'
						className='w-10 h-10 mr-2'
					/>
				</Link>
			</div>
		</div>
	)
}

export default HomePage
