import React from 'react'
import ArticlesPage from '../components/ArticlesPage'
import { fetchTelescopeArticles } from '../features/telescope/telescopeSlice'

const TelescopePage = () => {
	return (
		<ArticlesPage
			fetchArticles={fetchTelescopeArticles}
			sliceName='telescope'
			pageTitle='Подзорная труба'
		/>
	)
}

export default TelescopePage
