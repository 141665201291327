import React, { useState } from 'react'

// Пример данных для каждого члена команды
const teamMembers = [
	{
		name: 'Алиев Магомед Асиртудинович',
		position: 'Магистрант НИ ТПУ',
		description:
			'Разработка приложения на движке Unity, создание текста для аудиогида',
		image: '/images/Member-2.png',
		photo: '/images/Photo-1.jpg',
	},
	{
		name: 'Войленко Юрий Павлович',
		position: 'Магистрант НИ ТПУ',
		description:
			'Разработка приложения на движке Unity, наполнение контентом, создание 3D объектов',
		image: '/images/Member-2.png',
		photo: '/images/Photo-2.jpg',
	},
	{
		name: 'Кореневский Илья Валерьевич',
		position: 'Магистрант НИ ТПУ',
		description:
			'Наполнение контентом, разработка приложения на движке Unity, разработка сайта на React',
		image: '/images/Member-2.png',
		photo: '/images/Photo-3.jpg',
	},
	{
		name: 'Коровкин Виталий Александрович',
		position: 'Старший преподаватель НИ ТПУ',
		description:
			'Руководитель проекта, ревью кода, развёртывание приложение и сайта',
		image: '/images/Member-2.png',
		photo: '/images/Photo-4.jpg',
	},
	{
		name: 'Сорфонова Екатерина Игоревна',
		position: 'Магистрант ТГАСУ',
		description: 'Предпроектный анализ территории, разработка концепции МАФов',
		image: '/images/Member-2.png',
		photo: '/images/Photo-5.jpg',
	},
]

const AboutUs = () => {
	// Состояние для отслеживания, какая карточка перевернута
	const [flipped, setFlipped] = useState({})

	// Функция для переворота карточки
	const flipCard = index => {
		setFlipped(prev => ({ ...prev, [index]: !prev[index] }))
	}

	return (
		<div className='container text-white mx-auto p-4 mt-4'>
			<div className='max-w-6xl mx-auto'>
				<div className='max-w-[240px] mx-auto bg-black bg-opacity-70 p-4 rounded-3xl'>
					<h1 className='text-3xl font-bold mb-2 text-center'>О нас</h1>
				</div>

				<div className='max-w-[640px] mx-auto bg-black bg-opacity-70 p-4 mt-6 rounded-2xl'>
					<p className=' text-center'>
						На этой странице представлена информация о команде AI.Architects.
					</p>
				</div>

				<div className='max-w-[240px] mx-auto bg-black bg-opacity-70 p-4 mt-6 mb-6 rounded-2xl'>
					<p className=' text-center'>Переверни карточки :3</p>
				</div>

				<div className='mx-auto max-w-[960px] grid grid-cols-1 md:grid-cols-2 gap-3 mt-6 justify-center items-start'>
					{teamMembers.map((member, index) => (
						<div
							className={`flip-card mb-4 mx-auto max-w-[450px] cursor-pointer ${
								flipped[index] ? 'flipped' : ''
							}`}
							onClick={() => flipCard(index)}
							key={index}
						>
							<div className='flip-card-inner'>
								<div className='flip-card-front'>
									<img
										src={member.image}
										alt={member.name}
										className='w-full h-full object-cover rounded-2xl'
									/>
								</div>
								<div className='flip-card-back'>
									<img
										src={member.photo}
										alt={member.name}
										className='w-24 h-24 object-cover rounded-[50%]'
									/>
									<h2 className='font-bold text-lg mb-2'>{member.name}</h2>
									<p className='text-sm mb-2 text-yellow-500'>
										{member.position}
									</p>
									<p className='text-sm max-w-80 text-green-500'>
										{member.description}
									</p>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	)
}

export default AboutUs
