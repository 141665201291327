import { configureStore } from '@reduxjs/toolkit'
import sherginaMineReducer from '../features/sherginaMine/sherginaMineSlice'
import karbazBoardsReducer from '../features/karbazBoards/karbazBoardsSlice'
import telescopeReducer from '../features/telescope/telescopeSlice'
import discoveredReducer from '../features/discovered/discoveredSlice'
import campfireInstallationReducer from '../features/campfireInstallation/campfireInstallationSlice'

export const store = configureStore({
	reducer: {
		sherginaMine: sherginaMineReducer,
		karbazBoards: karbazBoardsReducer,
		telescope: telescopeReducer,
		discovered: discoveredReducer,
		campfireInstallation: campfireInstallationReducer,
	},
})
