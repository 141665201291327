import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const ArticlesPage = ({ fetchArticles, sliceName, pageTitle }) => {
	const dispatch = useDispatch()
	const articles = useSelector(state => state[sliceName].articles)
	const status = useSelector(state => state[sliceName].status)
	const error = useSelector(state => state[sliceName].error)
	const [activeArticleId, setActiveArticleId] = useState(null)

	const imageMap = {
		'Шахта Шергина': '/images/Tasks/SherginMine1.png',
		'Карбазные доски': '/images/Tasks/Karbaz1.png',
		'Подзорная труба': '/images/Tasks/Telescope1.png',
		'Стенд первооткрывателей': '/images/Tasks/Discovered1.png',
		'Инсталляция костра': '/images/Tasks/Campfire1.png',
	}

	useEffect(() => {
		if (status === 'idle') {
			dispatch(fetchArticles())
		}
	}, [status, dispatch, fetchArticles])

	const handleArticleClick = id => {
		setActiveArticleId(id)
		const readArticles = new Set(
			JSON.parse(localStorage.getItem('completedTasks') || '[]')
		)
		readArticles.add(id)
		localStorage.setItem('completedTasks', JSON.stringify([...readArticles]))
	}

	let content
	let activeArticleContent = null
	const articleImage = imageMap[pageTitle] || ''

	if (activeArticleId) {
		const activeArticle = articles.find(
			article => article.id === activeArticleId
		)
		if (activeArticle) {
			activeArticleContent = (
				<div className='max-w-[840px] mx-auto bg-black bg-opacity-80 p-4 rounded-3xl mt-8'>
					<h2 className='text-xl font-bold mb-2 text-center'>
						{activeArticle.description}
					</h2>
					{activeArticle.images?.map((image, index) => (
						<div key={index} className='flex justify-center'>
							<img
								src={image}
								alt={`Изображение ${index + 1}`}
								className='max-w-full h-auto mb-4' // Стили для адаптивности изображения
							/>
						</div>
					))}
					<p>{activeArticle.content}</p>
				</div>
			)
		}
	}

	if (status === 'loading') {
		content = <div>Loading...</div>
	} else if (status === 'succeeded') {
		content = (
			<div className='grid grid-cols-2 md:grid-cols-3 gap-4 mt-6'>
				{articles.map(article => (
					<div
						key={article.id}
						onClick={() => handleArticleClick(article.id)}
						className={`flex flex-col bg-black ${
							activeArticleId === article.id
								? 'bg-yellow-600'
								: 'hover:bg-opacity-70'
						} text-white font-bold py-1 px-3 rounded-lg m-1 cursor-pointer transition duration-300 ease-in-out`}
						style={{
							minHeight: '90px',
							maxWidth: '210px',
							justifyContent: 'center',
							textAlign: 'center',
						}}
					>
						<p>{article.description}</p>
					</div>
				))}
			</div>
		)
	} else if (status === 'failed') {
		content = <div>{error}</div>
	}

	return (
		<div className='container mx-auto p-4 flex flex-col items-center justify-center mt-6 text-white'>
			<div className='mx-auto bg-black bg-opacity-50 p-4 rounded-3xl'>
				<h1 className='text-3xl font-bold mb-2 text-center'>{pageTitle}</h1>
				{articleImage && (
					<img
						src={articleImage}
						alt='Selected article'
						className='hidden sm:block max-w-[600px] h-auto mt-4'
					/>
				)}
			</div>
			{content}
			<div className='content-area flex flex-col items-center w-full text-center mb-8'>
				{activeArticleContent}
			</div>
		</div>
	)
}

export default ArticlesPage
